import PropTypes from 'prop-types';
import React from 'react';
import { cloneDeep } from 'lodash';
import get from 'lodash/get';
import merge from 'lodash/merge';
import unset from 'lodash/unset';
import { ResourceLoader } from 'components/data/Resources';
import Setup from 'components/data/Setup';
import EditorData from '../../EditorData';
import EditorChanged from '../../EditorData/editor-changed';

/**
 * Dynamic data wrapper
 * This is a decorator class that adds the editor data features to input components
 * It looks at the model property (and some others) to insert data from the redux store automatically
 */
function EditorDynamicDataWrapper(WrappedComponent, defaultProps) {
    /**
     * Helper function to parse the props
     * This function enriches the props and fills in the data coming from the store as the value.
     * @param {*} dataProps The original props
     * @param {*} props The blockmodel. This is a model to the entire block. E.g. When BlockModel=email, and model=settings.title, the value is written to the title key in the settingsModel
     */
    const parseDataProps = (dataProps, props) => {
        let data = cloneDeep(dataProps);
        const blockModel = props.blockModel;
        const sourceDataBlockModel = props.sourceDataBlockModel;
        const template = props.data.template;

        // If we are in a TD template in the creative builder there can be default values set in the template.
        // We need the default value to show inside the inputs.
        // The creative builder always has a blockModel.
        const templateDefaultData = EditorData.getTemplateDesignerDefaultData(blockModel);
        // Default props. This is a possibility to set props in case they were not set by the original component. This is used for instance for a standard data location.
        if (defaultProps) {
            Object.keys(defaultProps).forEach((key) => {
                if (!data[key]) {
                    data[key] = defaultProps[key];
                }
            });
        }

        // Data model and a blockModel, translates a model to a value in the underlying object
        if (blockModel && data.model) {
            let value = EditorData.getValueFromModel(blockModel + '.' + data.model, false, props.localScope);

            const blockModelParts = blockModel.split('.');
            const overwritesIndex = blockModelParts.indexOf('overwrites');
            let formatKey = blockModelParts[overwritesIndex + 1];

            if (overwritesIndex === -1) formatKey = 'general';

            //This if statement and its content are legacy code. Template value is still used in template interface setups.
            if (!value && template) {
                value = template[formatKey];

                // if there is no overwite value available show the general value
                if (!value) value = template['general'];
            }

            // If there is template default value we merge that data with the value from the store
            if (templateDefaultData) {
                value = setTemplateValue(value, templateDefaultData, data, formatKey);
            }

            data.value = value;
            data.fullModel = blockModel + '.' + data.model;
        }
        // Data model, translates a model to a value in the underlying object
        else if (data.model) {
            let value = EditorData.getValueFromModel(data.model, false, props.localScope);

            if (!value && template) {
                value = template.general;
            }

            // If there is template default value we merge that data with the value from the store
            if (templateDefaultData) {
                value = setTemplateValue(value, templateDefaultData, data, 'general');
            }

            data.value = value;
            data.fullModel = data.model;
        }

        // Source data model, translates a model with a collection of data to the 'sourceData' prop in the underlying object
        if (sourceDataBlockModel && data.sourceDataModel) {
            const val = EditorData.getValueFromModel(sourceDataBlockModel + '.' + data.sourceDataModel, false, props.localScope);
            data.sourceData = val ? val : false;
        } else if (data.sourceDataModel) {
            const val = EditorData.getValueFromModel(data.sourceDataModel, false, props.localScope);
            data.sourceData = val ? val : false;
        }

        // Input object, pass on a full model from the campaign data and handles all elements as props
        if (data.input) {
            const campaignData = EditorData.getValueFromModel(data.input, false, props.localScope);
            const val = { ...campaignData };
            Object.keys(val).forEach((key) => {
                // In case of copy (with value.value), strip that for the output
                if (val[key].copy || val[key].value) {
                    val[key] = val[key].value;
                }
            });
            data = { ...data, ...val };
        }

        // Dynamic value option
        if (props.dynamicValueActive) {
            data.dynamicValueActive = true;
        }

        // Skip this for dynamic values, as we want to keep the formula
        EditorData.parseDataDeep(data, { blockModel: blockModel }, props.localScope);
        return data;
    };

    /**
     * This function makes sure that the template default value is visible in value if the value is not set.
     * @param {*} value current value
     * @param {*} templateDefaultData The template default value
     * @param {*} data The data object
     * @param {*} overwritesIndex The index of the overwrites in the blockModel
     * @param {*} formatKey The format key e.g. halfpage
     * @returns The new value overwritten by the template default value.
     */
    const setTemplateValue = (value, templateDefaultData, data, formatKey) => {
        if (
            data.type === 'textMultiLanguage' ||
            data.type === 'textSelectMultiLanguage' ||
            data.type === 'radioListMultiLanguage' ||
            data.type === 'selectMultiLanguage'
        ) {
            // If input is textMultiLanguage merge the value over the default value
            // In case of textMultiLanguage we always merge because the default value could contian multiple languages
            const formatSpecificDefaultValue = merge(cloneDeep(templateDefaultData['general']), templateDefaultData[formatKey]);
            const defaultValue = get(formatSpecificDefaultValue, data.model) || {};
            const defaultLanguage = Setup.get('defaultLanguage') || 'EN';
            if (defaultLanguage !== 'EN') {
                defaultValue[defaultLanguage] = defaultValue['EN'];
                unset(defaultValue, 'EN');
            }

            value = merge({}, defaultValue, value);
        } else if (value === undefined) {
            // Set the default value from the template if value is not set
            const formatSpecificDefaultValue = merge(cloneDeep(templateDefaultData['general']), templateDefaultData[formatKey]);
            // Relace language with EN. In template default data all data that is language specific is store in the EN language.
            const model = data.model.replace('[[language]]', 'EN');

            value = get(formatSpecificDefaultValue, `${model}`);

            // if there is no overwite value available show the general value
            if (!value) value = get(templateDefaultData, `general.${model}`);
        }

        return value;
    };

    const getPropsFromDataProps = (dataProps, props) => {
        return {
            ...dataProps,
            ...parseDataProps(dataProps, props)
        };
    };

    /**
     * DynamicDataDecorator
     * The dynamic data decorator class
     */
    class DynamicDataDecorator extends React.Component {
        static state = {
            data: {}
        };

        static propTypes = {
            data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
            ...WrappedComponent.propTypes
        };

        static defaultProps = {
            data: false,
            ...WrappedComponent.defaultProps
        };

        static getDerivedStateFromProps(nextProps) {
            return { data: getPropsFromDataProps(nextProps.data, nextProps) };
        }

        constructor(props) {
            super(props);

            this.state = { data: getPropsFromDataProps(props.data, props) };

            // Default value feature
            // Allows to set a default value in case that's needed
            if (this.state.data.value === undefined) {
                if (this.state.data.defaultValue !== undefined) {
                    this.state.data.value = this.state.data.defaultValue;
                    this.setDefaultData = true;
                } else {
                    this.state.data.value = false;
                    this.state.data.defaultValue = false;
                }
            }
        }

        componentDidMount() {
            if (this.setDefaultData) {
                this.onMutation(this.state.data.defaultValue);
            }
        }

        /**
         * On mutation function
         * Is called by the wrapped component to update data in the store
         * @param {string} value The new valou to be set
         * @param {string} type The type of data to be set. The standard is the current model, alternative is the sourceData.
         */
        onMutation = (value, type = '') => {
            const { linkedBlockModels = [], localScope = undefined } = this.props;
            let model = this.props.data.model;
            if (!model && defaultProps && defaultProps.model) {
                model = defaultProps.model;
            }

            // Save source data with block model.
            if (type === 'sourceData' && this.props.sourceDataBlockModel) {
                EditorData.setModel(this.props.sourceDataBlockModel + '.' + this.props.data.sourceDataModel, value, undefined, localScope);
            }
            // The source data model without block model
            else if (type === 'sourceData' && this.props.data.sourceDataModel) {
                EditorData.setModel(this.props.data.sourceDataModel, value, undefined, localScope);
            }
            // Save model with block model.
            else if (this.props.blockModel && model) {
                EditorData.setModel(
                    this.props.blockModel + '.' + model,
                    value,
                    linkedBlockModels.map((x) => x + '.' + this.props.data.model),
                    localScope
                );

                // Copy the set keys to the root of the block model if copyToBlockModelRoot is set and value is of type object.
                if (this.props.data && this.props.data.copyToBlockModelRoot && Object.keys(value) && Object.keys(value).length > 0) {
                    Object.entries(value).forEach(([key, value]) => {
                        EditorData.setModel(this.props.blockModel + '.' + key, value, [], localScope);
                    });
                }
            }
            // Set model of model
            else {
                EditorData.setModel(model, value, linkedBlockModels, localScope);
            }
            EditorChanged.setChanged(true);
        };

        render() {
            const { additionalProps = {} } = this.props;

            // In case of resources to be loaded, use the ResourceLoader to load all data before displaying the element
            if (this.state.data.resources || this.props.resources) {
                let resources = [];
                if (this.state.data.resources) {
                    resources = this.state.data.resources;
                }
                if (this.props.resources) {
                    resources = [...resources, ...this.props.resources];
                }

                return (
                    <ResourceLoader
                        WrappedComponent={WrappedComponent}
                        resourcesToLoad={resources}
                        data={this.state.data}
                        additionalProps={additionalProps}
                        onMutation={this.onMutation}
                    />
                );
            }
            // Otherwise, just wrap the component
            else {
                return <WrappedComponent {...this.state.data} {...additionalProps} onMutation={this.onMutation} language={EditorData.getLanguage()} />;
            }
        }
    }

    return DynamicDataDecorator;
}

export default EditorDynamicDataWrapper;
