import store from '../../../store';
import EditorData from '../EditorData';

/**
 * LanguageData
 * This class holds a set of helpers for language operations, including translation, exporting etc.
 */
export default class LanguageData {
    /**
     * Import the translations from a translation file
     * @param {*} language
     * @param {*} data
     */
    static importTranslations(language, data) {
        const currentLanguage = EditorData.getLanguage();
        LanguageData.convertTranslations(data, currentLanguage);

        store.dispatch({
            type: 'EDITOR_IMPORT_DATA',
            payload: {
                data: data
            }
        });
    }

    /**
     * Checks whether the language object in the current language is not set. If so, we assume that the English version was overwritten by a translation agency.
     * @param {*} obj
     * @param {*} language
     */
    static convertTranslations(obj, language) {
        if (!obj) {
            return;
        } else if (obj.multilanguage) {
            if (!obj[language] && obj['EN'] && obj['EN'].value) {
                obj[language] = obj['EN'];
                delete obj['EN'];
            }
        } else if (typeof obj === 'object') {
            Object.keys(obj).forEach((key) => {
                LanguageData.convertTranslations(obj[key], language);
            });
        }
    }

    /**
     * Get the translations and return the array
     * @param {*} language
     * @param {*} requiresTranslation
     */
    static translations(language, requiresTranslation = false) {
        const campaign = store.getState().editor.data;
        const output = {};
        this.exportTranslationsProcess(language, campaign, output, requiresTranslation, []);

        return output;
    }

    /**
     * Export Excel
     * @param {*} language
     * @param {*} requiresTranslation
     */
    static exportTranslationsToExcel(language, requiresTranslation = false) {
        const campaign = store.getState().editor.data;
        const output = [];
        this.exportTranslationsToExcelProcess(language, campaign, output, requiresTranslation, []);
        const data = {
            structure: [
                { label: 'EN', field: 'EN' },
                { label: 'language', field: 'language' }
            ],
            data: output
        };

        // Use axios to download the file
        fetch(process.env.APP_API_HOST + 'feeds/convertJSONToXLSX', {
            method: 'POST',
            body: JSON.stringify({ data: data }),
            responseType: 'blob',
            credentials: 'omit'
        })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'export_translations_' + language + '_' + EditorData.getValueFromModel('settings.title') + '.xlsx');
                document.body.appendChild(link);
                link.click();
            });
    }

    /**
     * Export to Excel subfunction
     * This is called for every object to loop through the data
     * @param {*} language
     * @param {*} obj
     * @param {*} output
     * @param {*} requiresTranslation
     * @param {*} modelParts
     */
    static exportTranslationsToExcelProcess(language, obj, output, requiresTranslation, modelParts) {
        if (!obj) {
            return;
        } else if (obj.multilanguage || (obj.EN && obj.EN.value)) {
            if (!obj[language]) {
                obj[language] = {};
            }

            output.push({ EN: obj['EN'] ? obj['EN'].value : '', language: obj[language].value });
        } else if (typeof obj === 'object') {
            Object.keys(obj).forEach((key) => {
                LanguageData.exportTranslationsToExcelProcess(language, obj[key], output, requiresTranslation, [...modelParts, key]);
            });
        }
    }

    /**
     * Export translations to a file and output to browser
     * @param {*} language
     * @param {*} requiresTranslation
     */
    static exportTranslations(language, requiresTranslation = false) {
        const campaign = store.getState().editor.data;
        const output = {};
        this.exportTranslationsProcess(language, campaign, output, requiresTranslation, []);

        if (!Object.keys(output) || Object.keys(output).length === 0) {
            return false;
        }

        // Make file downloadable
        const json = JSON.stringify(output);
        const url = URL.createObjectURL(new Blob([json], { type: 'application/json' }));

        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        a.href = url;
        a.download = 'translate_' + language + '_' + EditorData.getValueFromModel('settings.title') + '.json';
        a.click();
        window.URL.revokeObjectURL(url, []);

        return true;
    }

    /**
     * internal function to loop through the entire object tree to find translations
     * @param {*} language language to fetch
     * @param {*} obj the current data object
     * @param {*} output the current output object
     * @param {*} requiresTranslation if true, we only export the items that have requiresTranslation set
     * @param {*} modelParts The current path structure to the model
     */
    static exportTranslationsProcess(language, obj, output, requiresTranslation, modelParts) {
        if (!obj) {
            return;
        } else if (obj.multilanguage || (obj.EN && obj.EN.value)) {
            if (!obj[language]) {
                obj[language] = {};
            }
            if (obj['EN'] && obj['EN'].value) {
                obj[language]['referenceTranslation'] = obj['EN'].value;
            }

            if (!requiresTranslation || obj[language].requiresTranslation) {
                // Save
                modelParts.forEach((part, i) => {
                    if (!(typeof output[part] === 'object' && output[part] !== null)) {
                        output[part] = {};
                    }
                    if (modelParts.length - 1 == i && obj[language]) {
                        output[part] = { [language]: obj[language], multilanguage: true };
                    }
                    output = output[part];
                });
            }
        } else if (typeof obj === 'object') {
            Object.keys(obj).forEach((key) => {
                LanguageData.exportTranslationsProcess(language, obj[key], output, requiresTranslation, [...modelParts, key]);
            });
        }
    }
}
