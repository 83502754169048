import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import Store from 'types/store.type';
import cloneDeep from 'helpers/cloneDeep';
import store from '../../../store';

interface Props {
    localScopeUuid?: string;
    initialData: unknown;
    children?: ReactElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (data: any) => void;
    renderBlocks?: (localScopeUuid: string) => ReactElement;
    disableReset?: boolean;
}

const EditorLocalScope = ({ localScopeUuid = '', initialData, children = undefined, onChange, renderBlocks, disableReset }: Props) => {
    const [loaded, setLoaded] = React.useState(false);
    const uuid = useMemo(() => localScopeUuid || 'i_' + uuidv4(), []);
    // we are checking the localscopedata for any changes to let the parent component know for any changes
    const localScopeData = useSelector((state: Store) => state.editor.localScope?.[uuid]);

    // This useEffect is used to reset the localScopeData when the component is unmounted. This is to prevent memory leaks
    useEffect(() => {
        if (disableReset) return;

        return () => {
            store.dispatch({
                type: 'EDITOR_LOCALSCOPE_RESET',
                payload: {
                    localScope: uuid
                }
            });
        };
    }, []);

    /**
     * This useEffect is used to check if the localScopeData is equal to the initialData, if it is not then we are updating the localScopeData with the initialData
     * This will make it possible to have changes outside of the editorlocalscope component and still have the changes reflected inside the component
     */
    useEffect(() => {
        const changesOutsideComp = !isEqual(localScopeData, initialData);

        if (changesOutsideComp && loaded) {
            store.dispatch({
                type: 'EDITOR_LOCALSCOPE_SET',
                payload: {
                    localScope: uuid,
                    data: cloneDeep(initialData)
                }
            });
        }
    }, [initialData]);

    /**
     * This useEffect is used to notify the parent component about the changes in the localScopeData
     * We are also checking if the localScopeData is equal to the initialData, if it is then we are not calling the onChange function to prevent unnecessary re-renders
     */
    useEffect(() => {
        if (isEqual(localScopeData, initialData)) return;

        if (onChange) {
            debouncedChangeStateUpdate(localScopeData);
        }
    }, [localScopeData]);

    // This is to prevent an infinite loop when the initialData and localScopeData are setting each other
    const debouncedChangeStateUpdate = onChange && useCallback(debounce(onChange, 5), []);

    if (!loaded) {
        store.dispatch({
            type: 'EDITOR_LOCALSCOPE_SET',
            payload: {
                localScope: uuid,
                data: cloneDeep(initialData)
            }
        });

        setLoaded(true);
        return null;
    }

    // There is two possible to render the blocks, either by using the renderBlocks function or by using the children prop
    // If the renderBlocks function is provided then we are using that to render the blocks. The renderBlocks function is used when you
    // have a more advanced way of rendering the inputs, for example when you want to pass additional vars, custom components etc
    if (renderBlocks) {
        return renderBlocks(uuid);
    }

    if (!children) {
        return null;
    }

    return <React.Fragment>{React.cloneElement(children, { localScope: uuid })}</React.Fragment>;
};

export { EditorLocalScope };
