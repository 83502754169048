/**
 * Keep track of changes in the editor. So in some use cases we can determine whether a save is required.
 * In principle the changed variable can be set and read from anywhere in the code, but changes do not always trigger a rerender.
 * Therefor a hook is created to subscribe to changes: frontend\src\components\editor-data\EditorData\hooks\useEditorChanged.ts
 * Using the hook is the preffered way to subscribe to changes and set them. This class is only used to keep track of the changes.
 */
export default class EditorChanged {
    static changed = false;
    static subscribers = new Set();

    static subscribe(callback) {
        EditorChanged.subscribers.add(callback);
    }

    static unsubscribe(callback) {
        EditorChanged.subscribers.delete(callback);
    }

    static setChanged(value) {
        EditorChanged.changed = value;
        EditorChanged.notifySubscribers();
    }

    static notifySubscribers() {
        EditorChanged.subscribers.forEach((callback) => callback(EditorChanged.changed));
    }
}
