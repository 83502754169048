import EditorData from '../EditorData';
import SnackbarUtils from '../../ui-base/SnackbarUtils';

/**
 * EditorHistory
 * Holds all the historical editor data for using with undo
 * It saved new changes and allows you to reset older data. 
 */
class EditorHistory {
    static history = [];

    /**
     * Reset the history
     */
    static reset=()=>{
        EditorHistory.history=[];
    }

    /**
     * Add Item
     */
    static addItem=(data)=>{

        var d = new Date();
        var n = d.getTime();

        if (EditorHistory.history[0] && EditorHistory.history[0].time> n - 2000) {
            return;
        }

        EditorHistory.time=n;
        EditorHistory.history.unshift({'data':data,'time':n});

        if (EditorHistory.history.length>20) {
            EditorHistory.history.pop()
        }

    }

    /**
     * Undo the last action
     * Fetches the last version from the history and 
     */
    static undo() {

        if (EditorHistory.history.length==0) {
            return;
        }

        let campaign;
        campaign=EditorHistory.history.shift();
        if (campaign.time==EditorHistory.time) {
            if (EditorHistory.history.length==0) {
                EditorHistory.history.push(campaign);
                return;
            }
            else {
                campaign=EditorHistory.history.shift();
            }
        }

        if (EditorHistory.history.length==0) {
            EditorHistory.history.push(campaign);
        }

        EditorHistory.time=campaign.time;
        EditorData.setEditorState('data',{...campaign.data.data});
        SnackbarUtils.success('The last action was undone');
    }

}

export default EditorHistory;
